import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import {
  useGetEntitiesReportQuery,
  useGetEntityItemsQuery,
  useGetEntitySchemaQuery,
  useGetReferenceValuesForeignQuery,
} from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import {
  AppTableCell,
  AppTableCellJustify,
} from '../../../common/components/AppTable/AppTableCell'
import { Link, NavLink, useSearchParams } from 'react-router-dom'
import {
  EntityDataType,
  IReferenceValuesTypeObject,
} from '../../../core/api/dto/EntityDto'

import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { PDFDownloadLink } from '@react-pdf/renderer'
import NewTable from 'common/components/AppTable/NewTable'
import { AppFilterDropdown } from 'common/components/AppDropdown/AppFilterDropdown'
import AppDatePicker from 'common/components/AppDatePicker/AppDatePicker'
import { Space, Tag } from 'antd'
import { ItemRepresentByDataType } from '../Entity/ItemRepresentByDataType'

export const ArchiveListingPage = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  let entitySymbolKey = 'Инфоповод'
  const { data: entitySchema } = useGetEntitySchemaQuery({
    entitySymbolKey: entitySymbolKey!,
  })
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    setSearchParams('end=2025-01-16')
  }, [])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const { data: entityItems } = useGetEntityItemsQuery({
    entitySymbolKey: entitySymbolKey!,
    searchParamsString,
  })
  const [active, setActive] = useState(false)

  const { data: reportPrevious } = useGetEntitiesReportQuery({
    type: 'previous',
  })

  const { data: reportCurrent } = useGetEntitiesReportQuery({
    type: 'current',
  })

  const { data: usersItems } = useGetReferenceValuesForeignQuery({
    ForeignKey: 'coowner',
  })

  const usersSelectList = useMemo(() => {
    if (usersItems) {
      return usersItems.map((item) => ({
        value: item.id,
        name: item.title,
      }))
    }
  }, [usersItems])

  const { data: themeItems } = useGetReferenceValuesForeignQuery({
    ForeignKey: 'theme',
  })

  const themeSelectList = useMemo(() => {
    if (usersItems) {
      return themeItems?.map((item) => ({
        value: item.id,
        name: item.title,
      }))
    }
  }, [usersItems])

  const getRandomColor = (id: any, title: any) => {
    var letters =
      '0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor((1 / (id + i)) * 16)]
    }
    return color + '77'
  }

  const changeHandler = useCallback(
    (value, type) => {
      type == 'start' ? setStartDate(value) : setEndDate(value)
      searchParams.set(type, value.toISOString().slice(0, 10))
      console.log('searchParams', searchParams.toString())
      setSearchParams(searchParams.toString())
    },
    [searchParams, setSearchParams],
  )

  const tableConfig = useMemo(() => {
    const tableHeaders: string[] = ['ID']
    const width: (string | number)[] = []
    const tableSelectors: {
      renderItem: (item: any) => JSX.Element | string
    }[] = [
      {
        renderItem: (item) => {
          return <AppTableCell>{item['pk']}</AppTableCell>
        },
      },
    ]
    if (!entitySchema || !entityItems) {
      return { tableHeaders, width, tableSelectors }
    }
    if (entityItems && entitySchema) {
      let schemaKeys = [...entitySchema.headerKeys]
      /*schemaKeys.unshift(
        ...schemaKeys.splice(schemaKeys.indexOf('event_end_date'), 1),
      )
      schemaKeys.unshift(
        ...schemaKeys.splice(schemaKeys.indexOf('event_start_date'), 1),
      )*/
      schemaKeys.unshift(...schemaKeys.splice(schemaKeys.indexOf('title'), 1))
      schemaKeys.forEach((key) => {
        tableHeaders.push(entitySchema.fields[key]?.label)
        width.push('auto')
        if (entitySchema.schemaName !== entityItems.items[0]?.nameSchema) {
          tableSelectors.push({
            renderItem: () => {
              return <AppTableCell>NaN</AppTableCell>
            },
          })
        } else if (key === 'title') {
          tableSelectors.push({
            renderItem: (item) => {
              return (
                <AppTableCell>
                  <NavLink to={`/entity/Инфоповод/${item['pk']}`} className="value">
                    <p className="project-title_name">{item[key]}</p>
                    <p className="project-title_draft">
                      {item.is_published == false && 'Черновик'}
                    </p>
                  </NavLink>
                </AppTableCell>
              )
            },
          })
        } else if (key === 'category') {
          tableSelectors.push({
            renderItem: (item) => {
              return (
                <AppTableCell>
                  <Tag
                    color={getRandomColor(
                      item?.category?.id,
                      item?.category?.title,
                    )}
                    className="project-title_name"
                  >
                    {item?.category?.title}
                  </Tag>
                </AppTableCell>
              )
            },
          })
        } else {
          switch (entitySchema.fields[key].field_type) {
            case 'ForeignKey':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: IReferenceValuesTypeObject = item[key]
                  return (
                    <AppTableCell>
                      <ItemRepresentByDataType
                        itemData={currentItem}
                        //tableOptions={{ responsiblePersonViewHideIco: true }}
                      />
                    </AppTableCell>
                  )
                },
              })
              break
            case 'DateTimeField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: EntityDataType = item[key]
                  return (
                    <AppTableCell>
                      <div style={{ whiteSpace: 'normal' }}>
                        <AppTranslation
                          label={
                            'task_section_widget_date_formatter__txt_title'
                          }
                          options={{ date: new Date(`${currentItem}`) }}
                        />
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            case 'ManyToManyField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: IReferenceValuesTypeObject[] = item[key]
                  return (
                    <AppTableCell>
                      <div className={'table-cell-grouped-items'}>
                        {currentItem &&
                          currentItem.map((item, index) => {
                            return (
                              <ItemRepresentByDataType
                                key={`item-${key}-${index}`}
                                itemData={item}
                                isMulti={currentItem.length > 1}
                              />
                            )
                          })}
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            case 'TextField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: EntityDataType = item[key]
                  const isDescription = key === 'description'
                  return (
                    <AppTableCell>
                      <div
                        className={`${isDescription ? 'text-ellipsis' : ''}`}
                        style={isDescription ? { width: 200 } : {}}
                      >
                        {!!currentItem && typeof currentItem === 'string'
                          ? currentItem
                          : 'NaN'}
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            default:
              tableSelectors.push({
                renderItem: () => {
                  return <AppTableCell>NaN</AppTableCell>
                },
              })
          }
        }
      })
      tableHeaders.push('Действия')
      tableSelectors.push({
        renderItem: (item) => {
          return (
            <AppTableCell justifyCell={AppTableCellJustify.right}>
              <div className="entity-actions">

              </div>
            </AppTableCell>
          )
        },
      })
    }

    return { tableHeaders, width, tableSelectors }
  }, [entityItems, entitySchema])

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation
            label={AllRoutes.entityElements.name}
            options={{ entityName: entitySymbolKey }}
          />
        </h1>
        <div
          className={'header-actions-block'}
          style={entitySymbolKey == 'Инфоповод' ? { display: 'block' } : {}}
        >
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
            <div className={'header-filters-block'}>
              {entitySymbolKey != 'Памятные даты' &&
                entitySymbolKey != 'Угроза' && (
                  <AppFilterDropdown
                    searchParam={'responsible'}
                    propToShowInList={'name'}
                    valuePropName={'value'}
                    data={usersSelectList || []}
                    placeHolder={'Ответственный'}
                    withFilter={true}
                    style={{ width: 200 }}
                  />
                )}
              {entitySymbolKey == 'Инфоповод' && (
                <AppFilterDropdown
                  searchParam={'theme'}
                  propToShowInList={'name'}
                  valuePropName={'value'}
                  data={themeSelectList || []}
                  placeHolder={'Тема'}
                  withFilter={true}
                  style={{ width: 200 }}
                />
              )}
              {entitySymbolKey == 'Памятные даты' && (
                <AppFilterDropdown
                  searchParam={'creator'}
                  propToShowInList={'name'}
                  valuePropName={'value'}
                  data={usersSelectList || []}
                  placeHolder={'Создатель'}
                  withFilter={true}
                  style={{ width: 200 }}
                />
              )}
            </div>
            <AppDatePicker
              showTime={false}
              value={startDate}
              onChange={(value) => changeHandler(value, 'start')}
              label={'Дата начала'}
              error={false}
              disableDate={false}
              disabled={false}
              fullWidth={false}
            />
            {/*<AppDatePicker
              showTime={false}
              value={endDate}
              onChange={(value) => changeHandler(value, 'end')}
              label={'Дата окончания'}
              error={false}
              disableDate={false}
              disabled={false}
              fullWidth={false}
              />*/}
          </div>
          {/*
            <AppFilterDropdown
              searchParam={''}
              propToShowInList={'name'}
              valuePropName={'value'}
              data={publishedList || []}
              placeHolder={'Отчёт'}
              withFilter={true}
              withoutSearch={true}
              style={{ width: 200, marginLeft: 50 }}
              onChange={onChangeHandler}
            />
          */}
        </div>
      </div>
      {/*<AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableConfig.tableSelectors}
        data={entityItems ? entityItems : null}
        tableAsEntity={true}
        lastColAlignLeft={true}
            />*/}
      <div style={{ display: 'flex' }}>
        {entitySymbolKey == 'Инфоповод' && (
          <div className="back-office-header">
            Кол-во опубликованных: {entityItems?.total}
          </div>
        )}
      </div>
      <NewTable
        headerTitles={tableConfig.tableHeaders}
        tableDataSelectors={tableConfig.tableSelectors}
        dataTable={entityItems ? entityItems : null}
      />
    </>
  )
}
