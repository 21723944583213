import { DatePicker, Space } from 'antd'
import { useGetUserProfileQuery } from 'core/api/BaseApi'
import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { EntityBlock } from 'pages/AdminPage/Entity/EntityBlock'
import AppDatePicker from '../AppDatePicker/AppDatePicker'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../AppButton/AppButton'
import {
  useGetEntityByIdQuery,
  useGetEntityTypesQuery,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { Pie } from '@ant-design/plots'
import { LoadingOutlined } from '@ant-design/icons'

export const MaterialAnalytics = (props: { entity: any }) => {
  const now = new Date()
  now.setDate(now.getDate() - 6)
  const [startDate, setStartDate] = useState(now)
  const [endDate, setEndDate] = useState(new Date())
  const [postStat, setPostStat] = useState<any>()
  const [thread, setThread] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [fullPostsCount, setFullPostsCount] = useState<any>(0)
  const [likesCount, setLikesCount] = useState<any>(0)
  const [repostsCount, setRepostsCount] = useState<any>(0)
  const [friendlyCount, setFriendlyCount] = useState<any>(0)
  const [neturalCount, setNeturalCount] = useState<any>(0)
  const [commentCount, setCommentCount] = useState<any>(0)
  const [viewsCount, setViewsCount] = useState<any>(0)
  const [referenceFilter, setReferenceFilter] = useState<any>(0)
  const { data: entityNames } = useGetEntityTypesQuery()
  /*const { data: companyData } = useGetEntityByIdQuery({
    entitySymbolKey: 'Медиакампания',
    entityId: props.entity.reservedFields.pk.toString(),
  })*/

  useEffect(() => {
    if (props?.entity?.dynamicFields?.dynamicFields?.name == 'Материал')
      setReferenceFilter(
        props?.entity?.dynamicFields?.dynamicFields?.external_related_objects
          ?.length > 0
          ? [
              +props?.entity?.dynamicFields?.dynamicFields
                ?.external_related_objects,
            ]
          : [],
      )
  }, [])

  useEffect(() => {
    axios
      .post(
        API_URL + 'threads/get',
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setThread(response.data[0].id)
      })
      .catch((error) => console.log('error', error))
  }, [])

  useEffect(() => {
    if (+thread > 0) {
      //setLoading(true)
      axios
        .post(API_URL + '/content/getpostcount', body, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setPostStat(response.data)
          //setSmiCount(response.data)
          let keys = response.data ? Object.keys(response.data) : []
          let summ = 0
          for (let j = 0; j < keys.length; j += 1) {
            summ = summ + response.data[keys[j]]?.total?.posts
          }
          setFullPostsCount(summ)
          let likes = 0
          for (let j = 0; j < keys.length; j += 1) {
            likes = likes + response.data[keys[j]]?.total?.likes
          }
          setLikesCount(likes)
          let reposts = 0
          for (let j = 0; j < keys.length; j += 1) {
            reposts = reposts + response.data[keys[j]]?.total?.reposts
          }
          setRepostsCount(reposts)
        })
        .catch((error) => console.log('error', error))

      axios
        .post(API_URL + 'content/posts', bodyPostF, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setFriendlyCount(response.data.count)
          setLoading(false)
        })
        .catch((error) => console.log('error', error))

      axios
        .post(API_URL + 'content/posts', bodyPostN, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setNeturalCount(response.data.count)
          
        })
        .catch((error) => console.log('error', error))

      axios
        .post(API_URL + 'stats', bodyStatsC, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          let sum = 0
          response.data.graph_data.forEach((num: any) => {
            sum += num[1]
          })
          console.log(sum)
          setCommentCount(sum)
        })
        .catch((error) => console.log('error', error))

      axios
        .post(API_URL + 'stats', bodyStatsV, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          let sum = 0
          response.data.graph_data.forEach((num: any) => {
            sum += num[1]
          })
          console.log(sum)
          setViewsCount(sum)
        })
        .catch((error) => console.log('error', error))
    }
    setLoading(false)
  }, [startDate, endDate, thread, referenceFilter])

  const updateRef = (value: any) => {
    if (props?.entity?.dynamicFields?.dynamicFields?.name != 'Материал')
      setReferenceFilter(value)
    console.log('!!!!!!!', value)
  }

  const API_URL = 'https://apitest.glassen-it.com/component/socparser/'
  const body = {
    filter: {
      referenceFilter: referenceFilter,
    },
    from: startDate,
    thread_id: thread,
    to: endDate,
  }

  const bodyPostF = {
    filter: {
      referenceFilter: referenceFilter,
      network_id: [1, 2, 3, 4, 5, 7, 8, 10],
      repostoption: 'whatever',
      trustoption: null,
      friendly: [1, 2],
    },
    from: startDate,
    thread_id: thread,
    to: endDate,
    limit: 20,
    start: 0,
  }

  const bodyPostN = {
    filter: {
      referenceFilter: referenceFilter,
      network_id: [1, 2, 3, 4, 5, 7, 8, 10],
      repostoption: 'whatever',
      trustoption: null,
      friendly: [0],
    },
    from: startDate,
    thread_id: thread,
    to: endDate,
    limit: 20,
    start: 0,
  }

  const bodyStatsC = {
    thread_id: thread,
    from: startDate,
    to: endDate,
    type: 'comments',
  }

  const bodyStatsV = {
    thread_id: thread,
    from: startDate,
    to: endDate,
    type: 'views',
  }

  const data1 = [
    {
      type: 'Общее кол-во публикаций из СМИ',
      value: postStat?.gs?.total?.posts,
    },
    {
      type: 'Общее кол-во публикаций в соц. сетях',
      value: fullPostsCount - postStat?.gs?.total?.posts,
    },
  ]

  const config1 = {
    data: data1,
    angleField: 'value',
    colorField: 'type',
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
    legend: {
      color: {
        title: false,
        position: 'bottom',
      },
    },
  }
  const data2 = [
    {
      type: 'Количество источников админстрации(дружественные)',
      value: friendlyCount,
    },
    {
      type: 'Количество нейтральных источников',
      value: neturalCount,
    },
  ]

  const config2 = {
    data: data2,
    angleField: 'value',
    colorField: 'type',
    scale: { color: { range: ['#30BF78', '#FAAD14'] } },
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
    legend: {
      color: {
        title: false,
        position: 'bottom',
      },
    },
  }
  const data3 = [
    { type: 'Общее кол-во лайков', value: likesCount },
    { type: 'Общее кол-во репостов', value: repostsCount },
  ]

  const config3 = {
    data: data3,
    angleField: 'value',
    colorField: 'type',
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
    legend: {
      color: {
        title: false,
        position: 'bottom',
      },
    },
  }

  const printDocument = () => {
    const doc = new jsPDF()
    doc.text('', 20, 20)
    // @ts-ignore: Unreachable code error
    html2canvas(document.querySelector('#divToPrint')).then((canvas) => {
      var imgData = canvas.toDataURL('image/png')

      var imgWidth = 180
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight

      var doc = new jsPDF('p', 'mm')
      var position = 10

      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
      }
      doc.save('print.pdf')
    })
  }

  return (
    <>
      {loading ? (
        <div className="analitic-loader" style={{ height: '300px' }}>
          <LoadingOutlined />
        </div>
      ) : (
        <div className="mysection__content">
          <div
            className="actions-left-block"
            style={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                gap: '10px',
                display: 'flex',
              }}
            >
              <AppDatePicker
                showTime={false}
                value={startDate}
                onChange={(value: any) => setStartDate(value)}
                label={'Дата начала'}
                error={false}
                disableDate={false}
                disabled={false}
                fullWidth={false}
              />
              <AppDatePicker
                showTime={false}
                value={endDate}
                onChange={(value: any) => setEndDate(value)}
                label={'Дата окончания'}
                error={false}
                disableDate={false}
                disabled={false}
                fullWidth={false}
              />
            </div>
            <AppButton
              onClick={() => setTimeout(() => printDocument(), 1000)}
              color={AppButtonColor.aquaBlue}
              size={AppButtonSize.base}
              title={'Скачать отчёт'}
            />
          </div>
          <div id="divToPrint">
            <>
              <h1>{props?.entity?.dynamicFields?.dynamicFields?.name}</h1>
              <EntityBlock
                entitySymbolKey={
                  props?.entity?.dynamicFields?.dynamicFields?.name
                }
                entityId={+props.entity.reservedFields.pk}
                updateRef={updateRef}
              />
            </>
            {loading ? (
              <div className="analitic-loader" style={{ height: '300px' }}>
                <LoadingOutlined />
              </div>
            ) : (
              <>
                <h1>Аналитика</h1>
                <div className="analitic-table">
                  <div className="form-block">
                    <b>Общее количество публикаций по Мк</b>
                    <p>{fullPostsCount}</p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество публикаций из СМИ</b>
                    <p>{postStat?.gs?.total?.posts}</p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество публикаций в социальных сетях</b>
                    <p>{fullPostsCount - postStat?.gs?.total?.posts}</p>
                  </div>
                  <div className="form-block">
                    <b>Количество источников админстрации(дружественные)</b>
                    <p>{friendlyCount}</p>
                  </div>
                  <div className="form-block">
                    <b>Количество нейтральных источников</b>
                    <p>{neturalCount}</p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество инфоповодов</b>
                    <p>
                      {/*
                //@ts-ignore
                companyData?.entities['Инфоповод']?.length
        */}
                      {
                        entityNames?.find((it) => it.name == 'Инфоповод')
                          ?.items_count
                      }
                    </p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество готовых материалов</b>
                    <p>
                      {
                        entityNames?.find((it) => it.name == 'Материал')
                          ?.items_count
                      }
                    </p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество лайков</b>
                    <p>{likesCount}</p>
                  </div>
                  <div className="form-block">
                    <b>Общее количество репостов</b>
                    <p>{repostsCount}</p>
                  </div>
                </div>
                {fullPostsCount > 0 && (
                  <div className="analitic-graphs">
                    <Pie {...config1} />
                    <Pie {...config2} />
                    <Pie {...config3} />
                  </div>
                )}
              </>
            )}
            <div>
              {props?.entity?.dynamicFields?.dynamicFields?.name ==
                'Медиакампания' && (
                <>
                  <h1>Инфоповоды</h1>
                  {props?.entity?.reservedFields?.entities['Инфоповод']?.map(
                    (item: any, id: number) => (
                      <div key={id}>
                        <EntityBlock
                          entitySymbolKey={'Инфоповод'}
                          entityId={item.id}
                          updateRef={updateRef}
                        />
                      </div>
                    ),
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
