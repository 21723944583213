import React, { useEffect, useState } from 'react'
import { Checkbox, Divider, Modal } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'

type Props = {
  isModalVisible: boolean
  handleCancel: any
  allKeys: any
  updateChecked: any
  localRout?: string
}

const ColumnsModal: React.FC<Props> = ({
  isModalVisible,
  handleCancel,
  allKeys,
  updateChecked,
  localRout,
}: Props) => {
  let url = window.location.pathname

  const CheckboxGroup = Checkbox.Group
  const [defaultCheckedList, setDefaultCheckedList] = useState([])
  const [activeItem, setActiveItem] = useState<any>(
    localStorage.getItem('activeItem'),
  )

  const plainOptions = allKeys
  //const defaultCheckedList = allKeys;

  useEffect(() => {
    /*setDefaultCheckedList(localStorage.getItem(localUrl)
    ? localStorage.getItem(localUrl)?.split(",")
    : allKeys)*/
    setDefaultCheckedList(allKeys)
    console.log('checkedList.toString()', checkedList.toString())
    //updateChecked(allKeys)
  }, [url])

  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList)

  const checkAll = plainOptions?.length === checkedList?.length
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < plainOptions?.length

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions : [])
  }
  useEffect(() => {
    if (localStorage.getItem(window.location.pathname.split("/")[2])) {
      updateChecked(localStorage.getItem(activeItem)?.split(','))
      console.log('filter111')
    }
    if (!localStorage.getItem(window.location.pathname.split("/")[2])) {
      onCheckAllChange({ target: { checked: true } })
      console.log('filter222')
    }
  }, [allKeys])

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list)
  }

  const handleOk = () => {
    updateChecked(checkedList)
    handleCancel()
    localStorage.setItem(activeItem, checkedList.toString())
  }

  return (
    <Modal
      title="Настройка колонок"
      open={isModalVisible}
      onCancel={handleCancel}
      cancelText="Отменить"
      okText="Сохранить"
      onOk={handleOk}
      width="60%"
      maskClosable={false}
    >
      <>
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
          className="columns-check"
        />
        <Divider />
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Выделить все
        </Checkbox>
      </>
    </Modal>
  )
}

export default ColumnsModal
