import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AppInput, InputMaskType } from '../AppInput/AppInput'
import {
  formStateItemType,
  IAppFormHookFiled,
  IFormErrorState,
  useFormHook,
} from '../useFormHook'
import { AppDropdown } from '../AppDropdown/AppDropdown'
import { IFormErrorDto } from '../../../core/api/BaseApi'
import {
  AppTranslation,
  IAppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { DataTypeSelect } from '../AppDropdown/DataTypeSelect'
import { ElementDataSelector } from '../AppDropdown/ElementDataSelector'
import { TaskStatusSelect } from '../AppDropdown/TaskStatusSelect'
import { TaskPrioritySelect } from '../AppDropdown/TaskPrioritySelect'
import AppDatePicker from '../../components/AppDatePicker/AppDatePicker'
import { FormExecutors, IExecutorsState } from '../FormExecutors/FormExecutors'
import { EntityItemMultiselect } from '../AppDropdown/EntityItemMultiselect'
import { AppButtonColor, AppButtonSize } from '../AppButton/AppButton'
import AppInputFile from '../AppInputFile/AppInputFile'
import { FormExecutor } from '../FormExecutors/FormExecutor'
import { FormCoExecutor } from '../FormExecutors/FormCoExecutors'
import { FormEntity } from '../EntityFilter/FormEntity'

export enum inputControllerType {
  baseFields,
  freeSpace,
  hidden,
  dropdown,
  multiselect,
  input,
  inputInt,
  inputFloat,
  textarea,
  dataTypeSelect,
  infoBlockSelectExtractor,
  underConstruction,
  taskStatusSelect,
  taskPrioritySelect,
  datePicker,
  executors,
  executor,
  co_executor,
  responsible,
  entity,
  file,
}

export const inputTypeByFieldType: {
  [key: string]: inputControllerType
} = {
  BooleanField: inputControllerType.underConstruction,
  ManyToManyField: inputControllerType.multiselect,
  CharField: inputControllerType.input,
  DateField: inputControllerType.datePicker,
  FloatField: inputControllerType.inputFloat,
  TextField: inputControllerType.textarea,
  JSONField: inputControllerType.textarea,
  DateTimeField: inputControllerType.datePicker,
  ForeignKey: inputControllerType.infoBlockSelectExtractor,
}

interface IRowItemBase {
  name: string | number
  label?: translationLabelTape
  colClassName: string
  required?: string
  disabled?: boolean
  type: inputControllerType
}

interface IRowItemBaseFields {
  type: inputControllerType.baseFields
  colClassName: string
  rowItems: IRowItem[]
}

interface IRowItemInput extends IRowItemBase {
  type: inputControllerType.input
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: InputMaskType
  dataType?: never
}
interface IRowItemFormExecutors extends IRowItemBase {
  type: inputControllerType.executors
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}
interface IRowItemFormExecutor extends IRowItemBase {
  type: inputControllerType.executor
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemFormEntity extends IRowItemBase {
  type: inputControllerType.entity
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemFormCoExecutor extends IRowItemBase {
  type: inputControllerType.co_executor
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemFormResponsible extends IRowItemBase {
  type: inputControllerType.responsible
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}
interface IRowItemFreeSpace {
  type: inputControllerType.freeSpace
  colClassName: string
}
interface IRowItemFile extends IRowItemBase {
  type: inputControllerType.file
  multiple?: boolean
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}
interface IRowItemHidden {
  type: inputControllerType.hidden
}
interface IRowItemInputInt extends IRowItemBase {
  type: inputControllerType.inputInt
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: InputMaskType
  dataType?: never
}
interface IRowItemTaskStatusSelect extends IRowItemBase {
  type: inputControllerType.taskStatusSelect
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}
interface IRowItemTaskPrioritySelect extends IRowItemBase {
  type: inputControllerType.taskPrioritySelect
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}
interface IRowItemInputFloat extends IRowItemBase {
  type: inputControllerType.inputFloat
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: InputMaskType
  dataType?: never
}

interface IRowItemTextarea extends IRowItemBase {
  type: inputControllerType.textarea
  dropdownData?: never
  height?: never
  rows?: number
  inputMaskType?: never
  dataType?: never
}

interface IRowItemDropdown extends IRowItemBase {
  type: inputControllerType.dropdown
  rows?: never
  dropdownData?: string[]
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemMultiselect extends IRowItemBase {
  type: inputControllerType.multiselect
  rows?: never
  dropdownData?: string[]
  height?: never
  inputMaskType?: never
  dataType: string
}

interface IRowItemDataTypeSelector extends IRowItemBase {
  type: inputControllerType.dataTypeSelect
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemUnderConstruction extends IRowItemBase {
  type: inputControllerType.underConstruction
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType?: never
}

interface IRowItemInfoBlockSelectExtractor extends IRowItemBase {
  type: inputControllerType.infoBlockSelectExtractor
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: never
  dataType: string
}

interface IRowItemDatePicker extends IRowItemBase {
  type: inputControllerType.datePicker
  rows?: never
  dropdownData?: never
  height?: never
  inputMaskType?: InputMaskType
  dataType?: never
  required?: string
}

export type IRowItem =
  | IRowItemFreeSpace
  | IRowItemFormExecutors
  | IRowItemFormExecutor
  | IRowItemFormCoExecutor
  | IRowItemFormResponsible
  | IRowItemFormEntity
  | IRowItemInput
  | IRowItemDropdown
  | IRowItemTextarea
  | IRowItemDataTypeSelector
  | IRowItemInputInt
  | IRowItemInputFloat
  | IRowItemInfoBlockSelectExtractor
  | IRowItemTaskStatusSelect
  | IRowItemTaskPrioritySelect
  | IRowItemDatePicker
  | IRowItemUnderConstruction
  | IRowItemMultiselect
  | IRowItemBaseFields
  | IRowItemHidden
  | IRowItemFile

export interface IFormRowData {
  title: translationLabelTape | IAppTranslation | null
  canBeHide?: boolean
  rowItems: IRowItem[]
}

interface IOnChange {
  onChange: (newState: { [key: string]: formStateItemType }) => void
}

interface IFormRowProps extends IFormRowData, IOnChange {
  formState: {
    [key: string]: formStateItemType
  }
  formErrorState: IFormErrorState
  asBlocks?: boolean
}

interface IInputController extends IOnChange, IRowItemBase {
  type: inputControllerType
  value: formStateItemType
  error: string | boolean
  dropdownData?: string[]
  inputMaskType?: InputMaskType | null
  rows?: number
  height?: number | string
  dataType?: string
  asBlocks?: boolean
  multiple?: boolean
}

const InputController = React.memo(
  (props: IInputController) => {
    const changeHandler = useCallback(
      (value: formStateItemType | null) => {
        props.onChange({ [props.name]: value })
      },
      [props.onChange],
    )

    switch (props.type) {
      case inputControllerType.dropdown:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppDropdown
                fullWidth={true}
                label={props.label}
                data={props.dropdownData ? props.dropdownData : []}
                value={props.value as string}
                onChange={changeHandler}
                error={props.error}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.multiselect:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <EntityItemMultiselect
                label={props.label}
                defaultValue={props.value as number[]}
                onChange={changeHandler}
                error={props.error}
                disabled={props.disabled}
                dataType={props.dataType!}
                withFilter={true}
              />
            </div>
          </div>
        )
      case inputControllerType.taskStatusSelect:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <TaskStatusSelect
                label={props.label}
                defaultValue={props.value as string}
                onChange={changeHandler}
                error={props.error}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.taskPrioritySelect:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <TaskPrioritySelect
                label={props.label}
                defaultValue={props.value as string}
                onChange={changeHandler}
                error={props.error}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.dataTypeSelect:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <DataTypeSelect
                defaultValue={
                  typeof props.value === 'string'
                    ? props.value
                    : !!props.value &&
                      typeof props.value !== 'number' &&
                      props.value &&
                      // @ts-ignore
                      'id' in props.value &&
                      'name' in props.value &&
                      'description' in props.value
                    ? props.value
                    : null
                }
                onChange={changeHandler}
                error={props.error}
                valueRows={7}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.infoBlockSelectExtractor:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <ElementDataSelector
                defaultValue={
                  typeof props.value === 'number'
                    ? props.value
                    : !!props.value &&
                      typeof props.value !== 'string' &&
                      props.value &&
                      // @ts-ignore
                      'id' in props.value &&
                      'title' in props.value
                    ? props.value
                    : null
                }
                label={props.label}
                onChange={changeHandler}
                error={props.error}
                valueRows={7}
                disabled={props.disabled}
                dataType={props.dataType!}
              />
            </div>
          </div>
        )
      case inputControllerType.input:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInput
                fullWidth={true}
                value={props.value as string}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                disabled={props.disabled}
                inputMaskType={props.inputMaskType ? props.inputMaskType : null}
              />
            </div>
          </div>
        )
      case inputControllerType.executors:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <FormExecutors
                defaultValue={
                  props.value &&
                  typeof props.value !== 'number' &&
                  typeof props.value !== 'string' &&
                  // @ts-ignore
                  'executor' in props.value &&
                  'co_executor' in props.value
                    ? (props.value as IExecutorsState)
                    : null
                }
                error={props.error}
                onChange={changeHandler}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.executor:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <FormExecutor
                defaultValue={
                  props.value &&
                  typeof props.value !== 'number' &&
                  typeof props.value !== 'string' &&
                  // @ts-ignore
                  'executor' in props.value
                    ? (props.value as IExecutorsState)
                    : null
                }
                error={props.error}
                onChange={changeHandler}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.co_executor:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <FormCoExecutor
                defaultValue={
                  props.value &&
                  typeof props.value !== 'number' &&
                  typeof props.value !== 'string' &&
                  // @ts-ignore
                  'co_executor' in props.value
                    ? (props.value as IExecutorsState)
                    : null
                }
                error={props.error}
                onChange={changeHandler}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.responsible:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <FormCoExecutor
                defaultValue={
                  props.value ? (props.value as IExecutorsState) : null
                }
                error={props.error}
                onChange={changeHandler}
                disabled={props.disabled}
              />
            </div>
          </div>
        )
      case inputControllerType.entity:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <FormEntity onChange={changeHandler} />
            </div>
          </div>
        )
      case inputControllerType.inputInt:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInput
                fullWidth={true}
                value={props.value as string}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                disabled={props.disabled}
                inputMaskType={InputMaskType.integer}
              />
            </div>
          </div>
        )
      case inputControllerType.file:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              }${props.name} ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInputFile
                title={'Загрузить файл'}
                onChangeFiles={
                  changeHandler as unknown as (file: FileList | null) => void
                }
                inputName={`${props.name}`}
                multiple={true}
                value={props.value as FileList}
                accept={''}
                label={props.label}
                fullWidth={true}
                color={AppButtonColor.lightBlue}
                size={AppButtonSize.small}
                icoClassName={'an-ico-plus-thin'}
              />
            </div>
          </div>
        )
      case inputControllerType.inputFloat:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInput
                fullWidth={true}
                value={props.value as string}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                disabled={props.disabled}
                inputMaskType={InputMaskType.float}
              />
            </div>
          </div>
        )
      case inputControllerType.textarea:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInput
                fullWidth={true}
                value={props.value as string}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                isTextArea={true}
                disabled={props.disabled}
                rows={props.rows ? props.rows : undefined}
              />
            </div>
          </div>
        )
      case inputControllerType.datePicker:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              }  ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppDatePicker
                showTime={true}
                value={props.value as Date}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                disabled={props.disabled}
                fullWidth={true}
              />
            </div>
          </div>
        )
      default:
        return (
          <div className={props.colClassName}>
            <div
              className={`back-office-input-wrapper ${
                props.asBlocks ? 'as-blocks' : ''
              } ${
                props.required == 'general__txt_required_field'
                  ? 'base-fields'
                  : ''
              }`}
            >
              <AppInput
                fullWidth={true}
                value={props.value as string}
                onChange={changeHandler}
                label={props.label}
                error={props.error}
                disabled={true}
                inputMaskType={props.inputMaskType ? props.inputMaskType : null}
              />
            </div>
          </div>
        )
    }
  },
  (prev, next) => {
    return (
      prev.value === next.value &&
      prev.error === next.error &&
      prev.onChange === next.onChange
    )
  },
)

const FormRow = React.memo(
  ({
    title,
    canBeHide,
    formState,
    onChange,
    rowItems,
    formErrorState,
    asBlocks,
  }: IFormRowProps) => {
    const [isHidden, setIsHidden] = useState(canBeHide)
    const currentTitle = useMemo(() => {
      if (!title) {
        return null
      }
      if (typeof title === 'object') {
        return <AppTranslation label={title.label} options={title.options} />
      } else {
        return <AppTranslation label={title} />
      }
    }, [title])

    return (
      <React.Fragment>
        {!isHidden && (
          <>
            <div className="col-12">{title && <h3>{currentTitle}</h3>}</div>
            {rowItems.map((item, index) => {
              if (item.type === inputControllerType.freeSpace) {
                return (
                  <div key={`free-${index}`} className={item.colClassName} />
                )
              }
              if (item.type === inputControllerType.hidden) {
                return null
              }
              if (item.type === inputControllerType.baseFields) {
                const textArea = item.rowItems.find(
                  (item) =>
                    item.type === inputControllerType.textarea &&
                    item.name == 'description',
                )
                const projectDesc = item.rowItems.find(
                  (item) =>
                    item.type === inputControllerType.textarea &&
                    item.name != 'description',
                )
                return (
                  <React.Fragment key={`inputController-base-${index}`}>
                    <div className="col-4">
                      <div className="row">
                        {item.rowItems.map((subItem, subIndex) => {
                          if (
                            'name' in subItem &&
                            subItem.type !== inputControllerType.textarea &&
                            subItem.type !== inputControllerType.file
                          ) {
                            return (
                              <InputController
                                key={`inputController-${subItem.name}-${index}-${subIndex}`}
                                onChange={onChange}
                                value={
                                  formState[subItem.name]
                                    ? formState[subItem.name]
                                    : ''
                                }
                                error={formErrorState[subItem.name]}
                                dropdownData={subItem.dropdownData}
                                dataType={subItem.dataType}
                                asBlocks={asBlocks}
                                {...subItem}
                              />
                            )
                          } else return null
                        })}
                      </div>
                    </div>
                    <div className="col-8">
                      {textArea && 'name' in textArea && (
                        <InputController
                          key={`inputController-${textArea.name}`}
                          onChange={onChange}
                          value={
                            formState[textArea.name]
                              ? formState[textArea.name]
                              : ''
                          }
                          error={formErrorState[textArea.name]}
                          dropdownData={textArea.dropdownData}
                          asBlocks={asBlocks}
                          {...textArea}
                        />
                      )}
                      {projectDesc && 'name' in projectDesc && (
                        <InputController
                          key={`inputController-${projectDesc.name}`}
                          onChange={onChange}
                          value={
                            formState[projectDesc.name]
                              ? formState[projectDesc.name]
                              : ''
                          }
                          error={formErrorState[projectDesc.name]}
                          dropdownData={projectDesc.dropdownData}
                          asBlocks={asBlocks}
                          {...projectDesc}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )
              }
              return (
                <InputController
                  key={`inputController-${item.name}-${index}`}
                  onChange={onChange}
                  value={formState[item.name] ? formState[item.name] : ''}
                  error={formErrorState[item.name]}
                  dropdownData={item.dropdownData}
                  dataType={item.dataType}
                  asBlocks={asBlocks}
                  {...item}
                />
              )
            })}
          </>
        )}
        {canBeHide && (
          <div className={'col-12'}>
            <div
              className={'cursor-pointer color-Main-Blue'}
              onClick={() => setIsHidden((prev) => !prev)}
            >
              <AppTranslation
                label={
                  isHidden
                    ? 'Показать дополнительные поля'
                    : 'Скрыть дополнительные поля'
                }
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  },
)

export type initFormStateType =
  | {
      [key: string]: formStateItemType
    }
  | null
  | undefined

interface IPageFormController {
  formData: IFormRowData[]
  willSubmit: boolean
  formDataChangeHandler: null | ((data: any, isValid: boolean) => void)
  isValid?: any
  initFormState?: initFormStateType
  fetchError: IFormErrorDto | null
  dirtyFormCallBack: null | ((value: boolean) => void)
  asBlocks?: boolean
}

export const PageFormController = React.memo(
  ({
    formData,
    willSubmit,
    formDataChangeHandler,
    isValid,
    initFormState,
    fetchError,
    dirtyFormCallBack,
    asBlocks,
  }: IPageFormController) => {
    const formConfig = useMemo(() => {
      const result: IAppFormHookFiled[] = []
      formData.forEach((row) => {
        row.rowItems.forEach((item) => {
          if (
            item.type !== inputControllerType.freeSpace &&
            item.type !== inputControllerType.baseFields &&
            item.type !== inputControllerType.hidden
          ) {
            result.push({
              fieldName: item.name,
              required: item.required,
              inputTitle: item.label,
            })
          }
          if (item.type === inputControllerType.baseFields) {
            item.rowItems.forEach((subItem) => {
              if ('name' in subItem) {
                result.push({
                  fieldName: subItem.name,
                  required: subItem.required,
                  inputTitle: subItem.label,
                })
              }
            })
          }
        })
      })
      return result
    }, [formData])

    const {
      formState,
      formStateChangeHandler,
      formValidationHandler,
      formErrorState,
      setFormErrorStateHandler,
      isFormDirty,
      resetFormDirtyState,
    } = useFormHook({
      formConfig,
    })

    const handleBeforeunload = (evt: BeforeUnloadEvent) => {
      evt.preventDefault()
      evt.returnValue = ''
      return null
    }

    useEffect(() => {
      if (dirtyFormCallBack) {
        if (isFormDirty) {
          dirtyFormCallBack(true)
          window.addEventListener('beforeunload', handleBeforeunload)
        } else {
          dirtyFormCallBack(false)
        }
      }
      return () => {
        if (isFormDirty && dirtyFormCallBack) {
          window.removeEventListener('beforeunload', handleBeforeunload)
        }
      }
    }, [isFormDirty, dirtyFormCallBack])

    useEffect(() => {
      if (initFormState) {
        resetFormDirtyState()
        formStateChangeHandler(
          {
            ...initFormState,
          },
          true,
        )
      }
    }, [initFormState, formStateChangeHandler, resetFormDirtyState])

    useEffect(() => {
      if (fetchError && fetchError.data && fetchError.data.form_fields) {
        const result: IFormErrorState = {}
        Object.keys(fetchError.data.form_fields).forEach((key) => {
          result[key] = fetchError.data.form_fields[key][0]
        })
        setFormErrorStateHandler(result)
      }
    }, [fetchError, setFormErrorStateHandler])

    useEffect(() => {
      if (willSubmit && formDataChangeHandler) {
        formDataChangeHandler(
          formState,
          formValidationHandler(formState).isValid,
        )
      }
    }, [willSubmit, formDataChangeHandler, formState, formValidationHandler])

    const onChange = useCallback(
      (
        newState: { [key: string]: formStateItemType },
        isInitState?: boolean,
      ) => {
        formStateChangeHandler(newState, isInitState)
        isValid(newState, formValidationHandler(newState).isValid)
      },
      [formStateChangeHandler],
    )

    return (
      <div className="back-office-form">
        <div className="row">
          {formData.map((row, index) => {
            return (
              <FormRow
                {...row}
                formState={formState}
                onChange={onChange}
                key={`formRow-${row.title}-${index}`}
                formErrorState={formErrorState}
                asBlocks={asBlocks}
              />
            )
          })}
        </div>
      </div>
    )
  },
)
