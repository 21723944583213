import { SettingOutlined } from '@ant-design/icons'
import { Button, Pagination, Table } from 'antd'
import { useSearchParamsHelper } from 'helpers/setSearchParam'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import ColumnsModal from '../columns-modal'

/*const Table = ({ columns, data }: any) => {
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useResizeColumns,
    useFlexLayout,
  )

  return (
    <div className="table">
      <div className='thead'>
        {headerGroups.map((headerGroup, index) => (
          <div
            {...headerGroup.getHeaderGroupProps({
              // style: { paddingRight: '15px' },
            })}
            className="tr"
          >
            {headerGroup.headers.map((column, id) => (
              <div {...column.getHeaderProps()} className={`th ${
                id == 0 ? 'is_id' : ''
              }`}>
                {column.render('Header')}
                {
                  // @ts-ignore
                  column.canResize && (
                    <div
                      // @ts-ignore
                      {...column.getResizerProps()}
                      className={`${id!=0 ? 'resizer' : ''} ${
                        // @ts-ignore
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )
                }
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody">
        {rows.map((row) => {
          prepareRow(row)
          return (
            <div {...row.getRowProps()} className="tr">
              {row.cells.map((cell, id) => {
                return (
                  <div {...cell.getCellProps()} className={`td ${
                    id == 0 ? 'is_id' : ''
                  }`}>
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}*/

const NewTable = ({ headerTitles, tableDataSelectors, dataTable }: any) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState([1])
  const [loadMore, setLoadMore] = useState(false)
  const { setNewSearchParams } = useSearchParamsHelper({})
  const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false)
  const [allKeys, setAllKeys] = useState<any>([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [filterColumns, setFilterColumns] = useState<any>([])
  const { entitySymbolKey } = useParams()
  const url = window.location.pathname

  console.log('headerTitles', headerTitles)

  const handleCancel = () => {
    setIsColumnsModalVisible(false)
  }

  const updateChecked = (value: any) => {
    setCheckedKeys(value)
    let newColumns: any = []
    newColumns = value.map((key: any) => {
      return columns.filter((it: any) => it?.title == key)[0]
    })
    let filter = newColumns.filter((item: any) => item != undefined)
    setFilterColumns(filter)
  }

  const nextPage = useMemo(() => {
    return dataTable
      ? dataTable.limit * dataTable.page >= dataTable.total
        ? null
        : dataTable.page + 1
      : null
  }, [dataTable])

  const totalPages = useMemo(() => {
    return dataTable ? Math.ceil(dataTable.total / dataTable.limit) : null
  }, [dataTable])

  const columns = headerTitles?.map((item: any, id: number) => ({
    title: item,
    dataIndex: 'col' + id,
    key: 'col' + id,
  }))

  /*useEffect(() => {
    updateChecked(columns)
  }, [columns])*/

  useEffect(() => {
    updateData()
    setFilterColumns(defaultColumns)
  }, [headerTitles, tableDataSelectors, dataTable, url])

  useEffect(() => {
    const all = []
    if (totalPages)
      for (let i = 1; i <= totalPages; i++) {
        all.push(i)
      }
    setAllPages(all)
  }, [totalPages])

  useEffect(() => {
    if (loadMore && dataTable) {
      setNewSearchParams([
        { searchParamName: 'page', value: `${nextPage}` },
        { searchParamName: 'limit', value: `${dataTable.limit}` },
      ])
    }
  }, [loadMore, dataTable, nextPage, setNewSearchParams])

  const setNewPage = (newPage: number) => {
    if (dataTable) {
      setCurrentPage(newPage)
      setNewSearchParams([
        { searchParamName: 'page', value: `${newPage}` },
        { searchParamName: 'limit', value: `${dataTable.limit}` },
      ])
    }
  }

  const updateData = () => {
    console.log('headerTitles', headerTitles)
    const allKey = headerTitles
    setAllKeys(allKey)
  }

  const defaultColumns = columns

  const data = dataTable?.items?.map((item: any, id: number) => ({
    ['col' + 0]: tableDataSelectors[0]?.renderItem(item),
    ['col' + 1]: tableDataSelectors[1]?.renderItem(item),
    ['col' + 2]: tableDataSelectors[2]?.renderItem(item),
    ['col' + 3]: tableDataSelectors[3]?.renderItem(item),
    ['col' + 4]: tableDataSelectors[4]?.renderItem(item),
    ['col' + 5]: tableDataSelectors[5]?.renderItem(item),
    ['col' + 6]: tableDataSelectors[6]?.renderItem(item),
    ['col' + 7]: tableDataSelectors[7]?.renderItem(item),
    ['col' + 8]: tableDataSelectors[8]?.renderItem(item),
    ['col' + 9]: tableDataSelectors[9]?.renderItem(item),
    ['col' + 10]: tableDataSelectors[10]?.renderItem(item),
    ['col' + 11]: tableDataSelectors[11]?.renderItem(item),
    ['col' + 12]: tableDataSelectors[12]?.renderItem(item),
    ['col' + 13]: tableDataSelectors[13]?.renderItem(item),
    ['col' + 14]: tableDataSelectors[14]?.renderItem(item),
    ['col' + 15]: tableDataSelectors[15]?.renderItem(item),
    ['col' + 16]: tableDataSelectors[16]?.renderItem(item),
    ['col' + 17]: tableDataSelectors[17]?.renderItem(item),
    ['col' + 18]: tableDataSelectors[18]?.renderItem(item),
    ['col' + 19]: tableDataSelectors[19]?.renderItem(item),
    ['col' + 20]: tableDataSelectors[20]?.renderItem(item),
    ['col' + 21]: tableDataSelectors[21]?.renderItem(item),
    ['col' + 22]: tableDataSelectors[22]?.renderItem(item),
    ['col' + 23]: tableDataSelectors[23]?.renderItem(item),
  }))

  return (
    <div className="container">
      <div className="back-office-header">
        <Button className="btn" onClick={() => setIsColumnsModalVisible(true)}>
          <SettingOutlined />
          Настройка колонок
        </Button>
      </div>
      <ColumnsModal
        isModalVisible={isColumnsModalVisible}
        handleCancel={handleCancel}
        allKeys={allKeys}
        updateChecked={updateChecked}
      />
      {dataTable?.count > 0 ? (
        <Table
          columns={filterColumns.length > 1 ? filterColumns : defaultColumns}
          dataSource={data}
          pagination={false}
        />
      ) : (
        'Ничего не найдено'
      )}
      {dataTable?.limit && (
        <>
          <Pagination
            onChange={(value) => setNewPage(value)}
            total={dataTable?.total}
            pageSize={dataTable?.limit || 20}
            current={dataTable?.page}
            hideOnSinglePage={true}
            showSizeChanger={false}
          />
        </>
      )}
    </div>
  )
}

export default NewTable
